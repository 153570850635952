import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import LogoImage from '../../assets/images/krovus-crow-128.png';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class FeaturesTiles extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
        'features-tiles section center-content-mobile',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-tiles-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: (
          <div>
            <Image
                src={LogoImage}
                alt="Logo"
                width={96}
                height={96}
            />
            <span>Meet Poe</span>
          </div>
      ),
      paragraph: 'Poe, our AI assistant, is always there to save you time and reduce frustration. Simply click the shortcut buttons or prompt Poe in the chat window to perform some of the sample actions below.'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
          <div className="container">
            <div className={innerClasses}>
              <SectionHeader data={sectionHeader} className="center-content" />

              <div className="center-content">
                <Image
                    src={require('./../../assets/images/feature-ai-doc_generation.png')}
                    alt="Feature AI Doc Generation"
                    width={900}
                    height={256} />
              </div>

              <div className={tilesClasses}>
                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <h4 className="mt-0 mb-8 reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="300">
                      Synchronize documents
                    </h4>
                    <p className="m-0 text-sm reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="400">
                      Update text or entire sections across all related documents when a change is made in a document.
                    </p>
                  </div>
                </div>

                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <h4 className="mt-0 mb-8 reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="100">
                      Insert content
                    </h4>
                    <p className="m-0 text-sm reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="200">
                      Merge new documents into existing documents or insert relevant content from historical documents into new documents.
                    </p>
                  </div>
                </div>

                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <h4 className="mt-0 mb-8 reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="200">
                      Generate documents
                    </h4>
                    <p className="m-0 text-sm reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="300">
                      Create drafts and automatically populate templates with relevant information (based on working copy of RFP information or selection of historical documents).
                    </p>
                  </div>
                </div>
              </div>

              <div className="center-content">
                <Image
                    src={require('./../../assets/images/feature-ai-ask_questions.png')}
                    alt="Feature AI Ask Questions"
                    width={900}
                    height={256} />
              </div>

              <div className={tilesClasses}>
                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <h4 className="mt-0 mb-8 reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="200">
                      Summarize information
                    </h4>
                    <p className="m-0 text-sm reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="300">
                      Summarize large amounts of content to provide quick overviews and essential points.
                    </p>
                  </div>
                </div>

                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <h4 className="mt-0 mb-8 reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="200">
                      Ask questions
                    </h4>
                    <p className="m-0 text-sm reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="300">
                      Query historical documents for information and recommendations.
                    </p>
                  </div>
                </div>

                <div className="tiles-item">
                  <div className="tiles-item-inner">
                    <h4 className="mt-0 mb-8 reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="200">
                      Check compliance
                    </h4>
                    <p className="m-0 text-sm reveal-from-right" data-reveal-container=".tiles-item" data-reveal-delay="300">
                      Verify, create compliance matrix, and offer recommended fixes for regulation and standards compliance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;