import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import { writeEmail } from "../../utils/Firestore";
import logo from "../../assets/images/krovus-512.png";
import { validate } from "../../utils/EmailValidator";

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class HeroSplit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {email: "", isSubmit: null};
  }

  handleInput = (event) => {
    this.setState({email: event.target.value});
  }

  handleSubmit = () => {
    if (validate(this.state.email)) {
      writeEmail(this.state.email);
      this.setState({isSubmit: true});
    } else {
      this.setState({isSubmit: false});
    }
  }

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
        'hero section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const isSubmit = this.state.isSubmit;
    let message;

    if (isSubmit) {
      message = <p><strong>Thank you for your interest in Krovus!</strong></p>
    } else if (isSubmit == false ) {
      message = <p><strong>Please enter a valid email address</strong></p>
    }

    return (
        <section
            {...props}
            className={outerClasses}
        >
          <div className="container">
            <div className={innerClasses}>
              <div className={splitClasses}>
                <div className="split-item">
                  <div className="hero-content split-item-content center-content-mobile">
                    <Image
                      src={logo}
                      alt="Krovus"
                      width={512}
                      height={512}/>
                  </div>
                  <div className="hero-content split-item-content center-content-mobile">
                    <h4 className="mt-0 mb-24 reveal-from-left">
                      AI-powered Acquisitions Platform
                    </h4>
                    <p className="mt-0 mb-24 reveal-from-left" data-reveal-delay="200">
                      Focus on tasks requiring human judgment while our AI handles the repetitive and time-consuming elements of the acquisition process, from requirements development to acquisition planning.
                    </p>
                    <div className="reveal-from-left" data-reveal-delay="400">
                      <form>
                        <div className="krovus__hero-split__start">
                          <input className="krovus__hero-split__input"
                                 type="text"
                                 placeholder="Email Address"
                                 onChange={this.handleInput}
                                 ></input>
                          <Button onClick={this.handleSubmit} tag="a" color="primary" >
                            Sign Up For Updates
                          </Button>
                        </div>
                        {message}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

HeroSplit.propTypes = propTypes;
HeroSplit.defaultProps = defaultProps;

export default HeroSplit;