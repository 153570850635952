import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { PROJECT_ID } from "../secrets.env";
import { setDoc, doc} from "firebase/firestore";

const firebaseConfig = {
    projectId: PROJECT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

/**
* Write email to emails/ collection
*
* @param email user email
*/
export const writeEmail = async (email) => {
    try {
       await setDoc(doc(db, "email", email), {
            email: email
        });
    } catch (e) {
        console.error("Error saving email:", e);
    }
}